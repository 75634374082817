<template>
  <AppHeaderBase :menu="menu">
    <template #append>
      <div class="flex items-center gap-2">
        <AppSearch variant="tonal" />
        <VArrowBtn
          :href="links.dashboard"
          target="_blank"
          variant="tonal"
          class="decoration-none lt-md:hidden!"
        >
          Login
        </VArrowBtn>
        <GetStartedBtn
          class="lt-md:hidden!"
          cta="Get started free"
          color="white"
        />
      </div>
    </template>
  </AppHeaderBase>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import AppHeaderBase from "./AppHeaderBase.vue";
import { defineAppMenu } from "./lib";
import AppSearch from "@/components/elements/search/AppSearch.vue";

export default defineComponent({
  name: "SellAppHeader",
  components: { AppHeaderBase, AppSearch },
  setup() {
    const { links } = useRuntimeConfig().public;

    const menu = defineAppMenu({
      append: [
        { text: "Pricing", to: { name: "sell-pricing" } },
        { text: "FAQs", to: { name: "help-faqs" } },
      ],
    });

    return {
      links,
      menu,
    };
  },
});
</script>
