<template>
  <LayoutBase>
    <SellAppHeader />

    <v-layout class="overflow-inherit!">
      <v-main>
        <slot />
      </v-main>
    </v-layout>

    <SellAppFooter />
  </LayoutBase>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import LayoutBase from "@/components/layout/LayoutBase.vue";
import { SellAppHeader, SellAppFooter } from "@/components/layout";

export default defineComponent({
  components: {
    LayoutBase,
    SellAppHeader,
    SellAppFooter,
  },
});
</script>
