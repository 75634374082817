<template>
  <AppFooterBase
    v-slot="{ forBizLinks, resourceLinks, companyLinks, contactPhone }"
    class="bg-[rgba(10,10,10)] pb-6 pt-8 sm:pb-3 text-white"
  >
    <v-container
      class="col-span-8 grid grid-cols-8 items-start gap-x-2 gap-y-6 sm:col-span-16 sm:grid-cols-16 sm:gap-3"
    >
      <section
        class="col-span-full md:col-span-4 flex flex-col space-y-1 text-sm md:col-span-3"
      >
        <RouterLink to="/">
          <Logo size="40" color="white" lucid />
        </RouterLink>
      </section>
      <section class="col-span-4 flex flex-col space-y-1 text-sm md:col-span-3">
        <h2 class="list-title">For Business</h2>
        <ul class="space-y-1 list-none">
          <li v-for="link in forBizLinks" :key="link.text">
            <RouteLink v-bind="link" class="hover:text-orange" />
          </li>
        </ul>
      </section>
      <section class="col-span-4 flex flex-col space-y-1 text-sm md:col-span-3">
        <h2 class="list-title">Resources</h2>
        <ul class="space-y-1 list-none">
          <li v-for="link in resourceLinks" :key="link.text">
            <RouteLink v-bind="link" class="hover:text-orange" />
          </li>
        </ul>
      </section>
      <section
        class="col-span-full flex flex-col space-y-1 text-sm md:col-span-3"
      >
        <h2 class="list-title">Orie</h2>
        <ul class="space-y-1 list-none">
          <li v-if="contactPhone">
            <RouteLink class="hover:text-orange" :phone="contactPhone" />
          </li>
          <li v-for="link in companyLinks" :key="link.text">
            <RouteLink v-bind="link" class="hover:text-orange" />
          </li>
        </ul>
      </section>
    </v-container>
  </AppFooterBase>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import AppFooterBase from "./AppFooterBase.vue";

export default defineComponent({
  components: { AppFooterBase },
});
</script>
